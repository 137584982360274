




















import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import Util from '../../../lib/util'
import AbpBase from '../../../lib/abpbase'
import VesselType from '../../../store/entities/vesseltype'

@Component
export default class VesselTypeForm extends AbpBase {
    @Prop({type: Boolean, default: false}) value: boolean;
    @Prop({type: Boolean, default: false}) public toEdit: boolean;
    vesselType: VesselType = new VesselType();

    created() {
    }

    save() {
        (this.$refs.vesselTypeForm as any).validate(async (valid: boolean) => {
            if (valid) {
                await this.$store.dispatch({
                    type: this.toEdit ? 'vesseltype/update' : 'vesseltype/create',
                    data: this.vesselType
                });
                (this.$refs.vesselTypeForm as any).resetFields();
                this.$emit('save-success');
                this.$emit('input', false);
            }
        })
    }

    cancel() {
        (this.$refs.vesselTypeForm as any).resetFields();
        this.$emit('input', false);
    }

    visibleChange(value: boolean) {
        if (!value) {
            this.$emit('input', value);
        } else {
            if (this.toEdit) {
                this.vesselType = Util.extend(true, {}, this.$store.state.vesseltype.editVesselType);
            }
            else {
                this.vesselType = new VesselType();
            }
        }
    }

    vesselTypeRule = {
        name: [{required: true, message: this.L('FieldIsRequired', undefined, this.L('Name')), trigger: 'blur'}]
    }
}
